import { useEffect, useState } from "react";
import api from "../../lib/api";
import { useAuth } from "../../lib/authContext";
import type { AccessLevel, User } from "../../lib/types";
import { Button } from "../ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../ui/table";
import ManageAccessPopover from "./ManageAccessPopover";

export const UsersTable = () => {
	const [users, setUsers] = useState<User[]>([]);
	const { user } = useAuth();
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

	useEffect(() => {
		fetchUsers();
	}, []);

	const fetchUsers = async () => {
		const result = await api.getUserList(user?.id || "");
		setUsers(result.results);
	};

	const handleManageAccessClick = (userId: string) => {
		setSelectedUserId(userId);
		setIsPopoverOpen(true);
	};

	const closePopover = () => {
		setIsPopoverOpen(false);
		setSelectedUserId(null);
	};

	return (
		<div className="space-y-4 min-h-screen bg-white dark:bg-gray-900 p-6">
			<div className="flex justify-between items-center">
				<h1 className="text-2xl font-bold dark:text-white">Users</h1>
			</div>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead>Email</TableHead>
						<TableHead>Access Level</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{users.map((user) => (
						<TableRow key={user.id}>
							<TableCell>{user.email}</TableCell>
							<TableCell>
								<Button
									variant="outline"
									onClick={() => handleManageAccessClick(user.id)}
								>
									Manage Access
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{isPopoverOpen && selectedUserId && (
				<ManageAccessPopover userId={selectedUserId} onClose={closePopover} />
			)}
		</div>
	);
};
