import { useState } from "react";
import { PlusCircle, X } from "lucide-react";
import type {
  Section,
  PageLayoutData,
  CardItemData,
  CategoryItemData,
  CategorySectionData,
} from "../../../../src/types";
import type { ComponentType, ApiResponse } from "../../lib/types";
import { COMPONENT_TYPES } from "../../lib/constants";
import api from "../../lib/api";
import { useToast } from "../../hooks/use-toast";
import { useAuth } from "../../lib/authContext";
import { Button } from "../ui/button";

interface Props {
  onSuccess?: () => void;
  layoutData?: PageLayoutData;
}

const PageLayoutForm: React.FC<Props> = ({ onSuccess, layoutData }) => {
  const [formData, setFormData] = useState<PageLayoutData>({
    page: layoutData?.page || "",
    metadata: layoutData?.metadata || [],
  });

  const [error, setError] = useState<string>("");
  const { user } = useAuth();
  const { toast } = useToast();

  const validateSection = (section: Section): boolean => {
    if (section.type === "banner") {
      return Boolean(section.data.image);
    }
    if (section.type === "card") {
      return (
        section.data.length > 0 &&
        section.data.every(item => item.icon && item.title)
      ); // Only icon and title are mandatory
    }
    if (section.type === "carousel") {
      return (
        section.data.length > 0 &&
        section.data.every(item => item.image && item.link)
      );
    }
    if (section.type === "categories") {
      return Boolean(section.data.title);
    }
    return false;
  };

  const addNewSection = (): void => {
    setFormData(prev => ({
      ...prev,
      metadata: [
        ...prev.metadata,
        {
          type: "banner",
          data: { image: "", link: "" },
        },
      ],
    }));
  };

  const removeSection = (index: number): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.filter((_, i) => i !== index),
    }));
  };

  const updateSectionType = (index: number, type: ComponentType): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === index) {
          const initialData =
            type === "banner"
              ? { image: "", link: "" }
              : type === "card"
              ? [{ icon: "", title: "", subtitle: "" }]
              : type === "carousel"
              ? [{ image: "", link: "" }]
              : { title: "", rows: 2, columns: 3, items: [] }; // Initial categories data

          return {
            type,
            data: initialData,
          } as Section;
        }
        return section;
      }),
    }));
  };

  const updateSectionData = (index: number, data: any): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === index) {
          return { ...section, data } as Section;
        }
        return section;
      }),
    }));
  };

  const addCarouselItem = (sectionIndex: number): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "carousel") {
          return {
            ...section,
            data: [...section.data, { image: "", link: "" }],
          };
        }
        return section;
      }),
    }));
  };

  const removeCarouselItem = (
    sectionIndex: number,
    itemIndex: number,
  ): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "carousel") {
          return {
            ...section,
            data: section.data.filter((_, idx) => idx !== itemIndex),
          };
        }
        return section;
      }),
    }));
  };

  const updateCarouselItem = (
    sectionIndex: number,
    itemIndex: number,
    field: string,
    value: string,
  ): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "carousel") {
          const newData = [...section.data];
          newData[itemIndex] = {
            ...newData[itemIndex],
            [field]: value,
          };
          return { ...section, data: newData };
        }
        return section;
      }),
    }));
  };

  const addCardItem = (sectionIndex: number): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "card") {
          return {
            ...section,
            data: [...section.data, { icon: "", title: "", subtitle: "" }],
          };
        }
        return section;
      }),
    }));
  };

  const removeCardItem = (sectionIndex: number, itemIndex: number): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "card") {
          return {
            ...section,
            data: section.data.filter((_, idx) => idx !== itemIndex),
          };
        }
        return section;
      }),
    }));
  };

  const updateCardItem = (
    sectionIndex: number,
    itemIndex: number,
    field: keyof CardItemData,
    value: string,
  ): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "card") {
          const newData = [...section.data];
          newData[itemIndex] = {
            ...newData[itemIndex],
            [field]: value,
          };
          return { ...section, data: newData };
        }
        return section;
      }),
    }));
  };

  const addCategoryItem = (sectionIndex: number): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "categories") {
          return {
            ...section,
            data: {
              ...section.data,
              items: [
                ...section.data.items,
                { image: "", title: "", link: "" },
              ],
            },
          };
        }
        return section;
      }),
    }));
  };

  const removeCategoryItem = (
    sectionIndex: number,
    itemIndex: number,
  ): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "categories") {
          return {
            ...section,
            data: {
              ...section.data,
              items: section.data.items.filter((_, idx) => idx !== itemIndex),
            },
          };
        }
        return section;
      }),
    }));
  };

  const updateCategoryItem = (
    sectionIndex: number,
    itemIndex: number,
    field: keyof CategoryItemData,
    value: string,
  ): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "categories") {
          const newItems = [...section.data.items];
          newItems[itemIndex] = {
            ...newItems[itemIndex],
            [field]: value,
          };
          return {
            ...section,
            data: {
              ...section.data,
              items: newItems,
            },
          };
        }
        return section;
      }),
    }));
  };

  const updateCategorySettings = (
    sectionIndex: number,
    field: keyof CategorySectionData,
    value: string | number,
  ): void => {
    setFormData(prev => ({
      ...prev,
      metadata: prev.metadata.map((section, i) => {
        if (i === sectionIndex && section.type === "categories") {
          return {
            ...section,
            data: {
              ...section.data,
              [field]: value,
            },
          };
        }
        return section;
      }),
    }));
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setError("");

    try {
      if (!formData.page) {
        setError("Page name is required");
        return;
      }
      if (!formData.metadata.every(validateSection)) {
        setError("Please fill in all required fields");
        return;
      }

      let response: ApiResponse<null>;

      if (layoutData?.id) {
        response = await api.updatePageLayout(Number(layoutData.id), {
          page: formData.page,
          metadata: formData.metadata,
          updated_by: user?.id || "",
        });
      } else {
        response = await api.createPageLayout({
          page: formData.page,
          metadata: formData.metadata,
          updated_by: user?.id || "",
        });
      }

      if (response && "error" in response) {
        toast({
          title: "Failed to save page layout",
          description: response.error.message,
          variant: "destructive",
        });
      } else {
        toast({
          title: "Page layout saved successfully",
        });
        onSuccess?.();
      }
    } catch (err: any) {
      toast({
        title: "Failed to save page layout",
        description: `${err}`,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Page Name Input */}
        <div>
          <label
            htmlFor="pageName"
            className="block text-sm font-medium text-gray-700 dark:text-white"
          >
            Page Name
          </label>
          <input
            id="pageName"
            type="text"
            value={formData.page}
            onChange={e =>
              setFormData(prev => ({
                ...prev,
                page: e.target.value,
              }))
            }
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
            required
          />
        </div>

        {/* Sections */}
        <div className="space-y-4">
          {formData.metadata.map((section, sectionIndex) => (
            <div
              key={sectionIndex}
              className="p-4 border rounded-lg bg-gray-50 dark:bg-gray-900 dark:text-white"
            >
              <div className="flex justify-between items-center mb-4">
                <select
                  value={section.type}
                  onChange={e =>
                    updateSectionType(
                      sectionIndex,
                      e.target.value as ComponentType,
                    )
                  }
                  className="rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                >
                  {COMPONENT_TYPES.map(type => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
                <Button
                  variant="destructive"
                  onClick={() => removeSection(sectionIndex)}
                >
                  <X size={20} />
                </Button>
              </div>

              {/* Section Content */}
              {section.type === "banner" ? (
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-white">
                      Image URL
                    </label>
                    <input
                      type="text"
                      value={section.data.image}
                      onChange={e =>
                        updateSectionData(sectionIndex, {
                          ...section.data,
                          image: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-white">
                      Link
                    </label>
                    <input
                      type="text"
                      value={section.data.link}
                      onChange={e =>
                        updateSectionData(sectionIndex, {
                          ...section.data,
                          link: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                    />
                  </div>
                </div>
              ) : section.type === "card" ? (
                <div className="space-y-4">
                  {section.data.map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className="p-4 border rounded-lg bg-white dark:bg-gray-900 dark:text-white"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="font-medium">
                          Card {itemIndex + 1}
                        </span>
                        <Button
                          variant="destructive"
                          onClick={() =>
                            removeCardItem(sectionIndex, itemIndex)
                          }
                        >
                          <X size={20} />
                        </Button>
                      </div>
                      <div className="space-y-2">
                        <input
                          type="text"
                          placeholder="Icon URL"
                          value={item.icon}
                          onChange={e =>
                            updateCardItem(
                              sectionIndex,
                              itemIndex,
                              "icon",
                              e.target.value,
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                          required
                        />
                        <input
                          type="text"
                          placeholder="Title"
                          value={item.title}
                          onChange={e =>
                            updateCardItem(
                              sectionIndex,
                              itemIndex,
                              "title",
                              e.target.value,
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                          required
                        />
                        <input
                          type="text"
                          placeholder="Subtitle"
                          value={item.subtitle}
                          onChange={e =>
                            updateCardItem(
                              sectionIndex,
                              itemIndex,
                              "subtitle",
                              e.target.value,
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                        />
                      </div>
                    </div>
                  ))}
                  <Button onClick={() => addCardItem(sectionIndex)}>
                    <PlusCircle size={20} className="mr-2" />
                    Add Card Item
                  </Button>
                </div>
              ) : section.type === "carousel" ? (
                <div className="space-y-4">
                  {section.data.map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className="p-4 border rounded-lg bg-white dark:bg-gray-900 dark:text-white"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="font-medium">
                          Carousel Item {itemIndex + 1}
                        </span>
                        <Button
                          variant="destructive"
                          onClick={() =>
                            removeCarouselItem(sectionIndex, itemIndex)
                          }
                        >
                          <X size={20} />
                        </Button>
                      </div>
                      <div className="space-y-2">
                        <input
                          type="text"
                          placeholder="Image URL"
                          value={item.image}
                          onChange={e =>
                            updateCarouselItem(
                              sectionIndex,
                              itemIndex,
                              "image",
                              e.target.value,
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                          required
                        />
                        <input
                          type="text"
                          placeholder="Link"
                          value={item.link}
                          onChange={e =>
                            updateCarouselItem(
                              sectionIndex,
                              itemIndex,
                              "link",
                              e.target.value,
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                          required
                        />
                      </div>
                    </div>
                  ))}
                  <Button onClick={() => addCarouselItem(sectionIndex)}>
                    <PlusCircle size={20} className="mr-2" />
                    Add Carousel Item
                  </Button>
                </div>
              ) : (
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-white">
                      Title
                    </label>
                    <input
                      type="text"
                      value={section.data.title}
                      onChange={e =>
                        updateCategorySettings(
                          sectionIndex,
                          "title",
                          e.target.value,
                        )
                      }
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                      required
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-white">
                        Rows
                      </label>
                      <input
                        type="number"
                        min="1"
                        value={section.data.rows}
                        onChange={e =>
                          updateCategorySettings(
                            sectionIndex,
                            "rows",
                            parseInt(e.target.value),
                          )
                        }
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-white">
                        Columns
                      </label>
                      <input
                        type="number"
                        min="1"
                        value={section.data.columns}
                        onChange={e =>
                          updateCategorySettings(
                            sectionIndex,
                            "columns",
                            parseInt(e.target.value),
                          )
                        }
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                      />
                    </div>
                  </div>
                  {section.data.items.map((item, itemIndex) => (
                    <div
                      key={`category-${sectionIndex}-${itemIndex}`}
                      className="p-4 border rounded-lg bg-white dark:bg-gray-900 dark:text-white"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="font-medium">
                          Category Item {itemIndex + 1}
                        </span>
                        <Button
                          variant="destructive"
                          onClick={() =>
                            removeCategoryItem(sectionIndex, itemIndex)
                          }
                        >
                          <X size={20} />
                        </Button>
                      </div>
                      <div className="space-y-2">
                        <input
                          type="text"
                          placeholder="Image URL"
                          value={item.image}
                          onChange={e =>
                            updateCategoryItem(
                              sectionIndex,
                              itemIndex,
                              "image",
                              e.target.value,
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                        />
                        <input
                          type="text"
                          placeholder="Title"
                          value={item.title}
                          onChange={e =>
                            updateCategoryItem(
                              sectionIndex,
                              itemIndex,
                              "title",
                              e.target.value,
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                        />
                        <input
                          type="text"
                          placeholder="Link"
                          value={item.link}
                          onChange={e =>
                            updateCategoryItem(
                              sectionIndex,
                              itemIndex,
                              "link",
                              e.target.value,
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-gray-900 dark:text-white"
                        />
                      </div>
                    </div>
                  ))}
                  <Button onClick={() => addCategoryItem(sectionIndex)}>
                    <PlusCircle size={20} className="mr-2" />
                    Add Category Item
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Add Section Button */}
        <Button onClick={addNewSection}>
          <PlusCircle size={20} className="mr-2" />
          Add Section
        </Button>

        {error && <div className="text-red-600 text-sm">{error}</div>}

        {/* Submit Button */}
        <Button type="submit" className="w-full">
          Save Layout
        </Button>
      </form>
    </div>
  );
};

export default PageLayoutForm;
