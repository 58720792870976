import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import { ProtectedRoute } from "./components/auth/protectedRoute";
import { Dashboard } from "./components/dashboard/Dashboard";
import { EntityTable } from "./components/entities/EntityTable";
import { OrderTable } from "./components/orders/OrderTable";
import { PharmacyStats } from "./components/pharmacies/PharmacyStats";
import { UsersTable } from "./components/users/UsersTable";
import { AuthProvider } from "./lib/authContext";
import { ThemeProvider } from "./lib/themeContext";
import PageLayouts from "./components/virtual_pharmacy/PageLayouts";
import { tracker } from "./lib/tracker";

function App() {
  useEffect(() => {
    tracker.start();
    return () => tracker.stop();
  }, []);

  return (
    <ThemeProvider>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        <Router>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/orders"
                element={
                  <ProtectedRoute>
                    <OrderTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pharmacies"
                element={
                  <ProtectedRoute>
                    <PharmacyStats />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/entities"
                element={
                  <ProtectedRoute>
                    <EntityTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <ProtectedRoute>
                    <UsersTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/page_layouts"
                element={
                  <ProtectedRoute>
                    <PageLayouts />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AuthProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
