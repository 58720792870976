import { Search } from "lucide-react";
import type React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { initAnalytics } from "../../lib/analytics";
import { api } from "../../lib/api";
import { useAuth } from "../../lib/authContext";
import { modeIcons, statusColors } from "../../lib/constants";
import { supabase } from "../../lib/supabase";
import type { Order } from "../../lib/types";
import { formatDate } from "../../lib/utils";
import { COMMANDS } from "../../services/mockDataService";
import { Filters } from "../ui/Filters";
import { StatusCard } from "../ui/StatusCard";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Input } from "../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { OrderDrawer } from "./OrderDrawer";

interface ApiOrder {
  id: string;
  entity_name: string;
  vendor_name: string;
  invoice_number: string;
  invoice_date: string;
  status: string;
  updated_at: string;
  mode: string;
  gmv: number;
  net_amount: number;
}

export const OrderTable: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const navigate = useNavigate();
  const { accessLevel } = useAuth();
  const [searchParams] = useSearchParams();
  const selectedOrderId = searchParams.get("orderId");
  const selectedStartDate = searchParams.get("startDate");
  const selectedEndDate = searchParams.get("endDate");
  const selectedStatus = searchParams.get("status");

  const [startDate, setStartDate] = useState<string>(
    selectedStartDate ?? new Date().toISOString().split("T")[0],
  );
  const [endDate, setEndDate] = useState<string>(
    selectedEndDate ?? new Date().toISOString().split("T")[0],
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilter, setSearchFilter] = useState<string>(
    searchParams.get("search") ?? "",
  );
  const [groupedOrders, setGroupedOrders] = useState<
    { status: string; count: string }[]
  >([]);
  const [statusFilter, setStatusFilter] = useState<string>(
    selectedStatus ?? "",
  );
  const [isCommandOpen, setIsCommandOpen] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [mixpanelInstance, setMixpanelInstance] = useState<any>(null);
  const [selectedCity, setSelectedCity] = useState<string>("all");
  const [selectedProvider, setSelectedProvider] = useState<string>("all");
  const [selectedMode, setSelectedMode] = useState<string>("all");

  supabase.auth.getSession().then(({ data: { session } }) => {
    const mp = initAnalytics();
    if (mp && session?.user) {
      // @ts-ignore
      mp.identify(session?.user?.id);
      // @ts-ignore
      mp.people.set({
        $email: session?.user?.email,
      });
      setMixpanelInstance(mp);
    }
  });

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsCommandOpen(open => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  useEffect(() => {
    const startParam = searchParams.get("startDate");
    const endParam = searchParams.get("endDate");
    const statusParam = searchParams.get("status");

    if (startParam) {
      setStartDate(startParam);
    }
    if (endParam) {
      setEndDate(endParam);
    }
    if (statusParam) {
      setStatusFilter(statusParam);
    }
  }, [searchParams]);

  const fetchOrders = async () => {
    try {
      const result = await api.getOrders({
        start_date: startDate,
        end_date: endDate,
        entities: accessLevel?.entities?.join(","),
      });
      const modifiedOrders = result.orders.data.map((order: ApiOrder) => ({
        ...order,
        partner: order.entity_name,
        vendor: order.vendor_name,
      }));
      setOrders(modifiedOrders);
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    }
  };

  const fetchGroupedOrders = async () => {
    try {
      const result = await api.getGroupedOrders({
        group_by: "status",
        start_date: startDate,
        end_date: endDate,
      });
      setGroupedOrders(result);
    } catch (error) {
      console.error("Failed to fetch grouped orders:", error);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: fetchOrders and fetchGroupedOrders are not dependencies
  useEffect(() => {
    if (startDate && endDate) {
      const fetchData = async () => {
        await Promise.all([fetchOrders(), fetchGroupedOrders()]);
      };
      fetchData();
    }
  }, [startDate, endDate]);

  const updateSearchParams = (key: string, value: string) => {
    const params = new URLSearchParams(searchParams);
    params.set(key, value);
    if (mixpanelInstance) {
      mixpanelInstance.track("search_orders", {
        search_filter: searchFilter,
        status_filter: statusFilter,
        start_date: startDate,
        end_date: endDate,
        order_id: selectedOrderId,
      });
    }
    navigate(`/orders?${params.toString()}`);
  };

  useEffect(() => {
    const filtered = orders
      .filter(order => order.status.includes(statusFilter))
      .filter(order =>
        order.invoice_number
          ? order.invoice_number.includes(searchFilter)
          : false,
      );
    if (mixpanelInstance) {
      mixpanelInstance.track("filtered_orders", {
        search_filter: searchFilter,
        status_filter: statusFilter,
      });
    }
    setFilteredOrders(filtered);
    setCurrentPage(1);
  }, [mixpanelInstance, orders, statusFilter, searchFilter]);

  return (
    <div className="space-y-4 min-h-screen bg-white dark:bg-gray-900 p-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold dark:text-white">Orders</h1>
        <Filters
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
          selectedMode={selectedMode}
          setSelectedMode={setSelectedMode}
          startDate={startDate}
          setStartDate={date => {
            setStartDate(date);
            updateSearchParams("startDate", date);
          }}
          endDate={endDate}
          setEndDate={date => {
            setEndDate(date);
            updateSearchParams("endDate", date);
          }}
        />
        <div className="flex items-center space-x-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  className="flex items-center gap-2 px-3 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md dark:text-gray-300 border dark:border-gray-700"
                  type="button"
                  onClick={() => setIsCommandOpen(true)}
                >
                  <Search size={16} />
                  <span className="hidden sm:inline-flex items-center">
                    Search
                    <kbd className="ml-2 pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground">
                      <span className="text-xs">⌘</span>K
                    </kbd>
                  </span>
                </button>
              </TooltipTrigger>
              <TooltipContent className="dark:bg-gray-800 dark:text-white">
                Search orders (⌘K)
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4">
        {groupedOrders.map(order => (
          <StatusCard
            key={order.status}
            status={order.status}
            count={order.count}
            isSelected={statusFilter === order.status}
            onClick={() => {
              setStatusFilter(order.status);
              updateSearchParams("status", order.status);
              setCurrentPage(1);
            }}
          />
        ))}
      </div>

      <div className="flex justify-between items-center">
        <div className="flex space-x-2">
          <Input
            type="text"
            placeholder="Search Order"
            className="w-64 dark:bg-gray-800 dark:text-white dark:border-gray-700"
            value={searchFilter}
            onChange={e => setSearchFilter(e.target.value)}
          />
          <Button
            variant="outline"
            className="dark:bg-gray-800 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700"
            onClick={() => {
              setStatusFilter("");
              setSearchFilter("");
              navigate("/orders");
            }}
          >
            Reset Filters
          </Button>
        </div>
        <div className="flex items-center space-x-2 dark:text-white">
          <span>
            {filteredOrders.length > 0
              ? `${(currentPage - 1) * 100 + 1} - ${Math.min(
                  currentPage * 100,
                  filteredOrders.length,
                )} of ${filteredOrders.length}`
              : "0 - 0 of 0"}
          </span>
          <Button
            variant="outline"
            size="icon"
            className="dark:bg-gray-800 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </Button>
          <Button
            variant="outline"
            size="icon"
            className="dark:bg-gray-800 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage * 100 >= filteredOrders.length}
          >
            &gt;
          </Button>
        </div>
      </div>

      <div className="rounded-md border dark:border-gray-700">
        <Table>
          <TableHeader>
            <TableRow className="dark:border-gray-700 dark:bg-gray-800">
              <TableHead className="dark:text-gray-400">Order ID</TableHead>
              <TableHead className="dark:text-gray-400">Placed on</TableHead>
              <TableHead className="dark:text-gray-400">Partner</TableHead>
              <TableHead className="dark:text-gray-400">Status</TableHead>
              <TableHead className="dark:text-gray-400">Last Updated</TableHead>
              <TableHead className="dark:text-gray-400">TAT</TableHead>
              <TableHead className="dark:text-gray-400">GMV</TableHead>
              <TableHead className="dark:text-gray-400">NMV</TableHead>
              <TableHead className="dark:text-gray-400">Mode</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredOrders.length > 0 ? (
              filteredOrders
                .slice((currentPage - 1) * 100, currentPage * 100)
                .map(order => (
                  <TableRow
                    key={order.id}
                    className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:border-gray-700 dark:bg-gray-800"
                    onClick={() => {
                      updateSearchParams("orderId", order.id);
                    }}
                  >
                    <TableCell className="dark:text-gray-300">
                      {order.invoice_number}
                    </TableCell>
                    <TableCell className="dark:text-gray-300">
                      {new Date(order.invoice_date ?? "").toLocaleDateString()}
                    </TableCell>
                    <TableCell className="dark:text-gray-300">
                      {order.partner}
                    </TableCell>
                    <TableCell>
                      <Badge className={`${statusColors[order.status]}`}>
                        {order.status}
                      </Badge>
                    </TableCell>
                    <TableCell className="dark:text-gray-300">
                      {formatDate(new Date(order.updated_at))}
                    </TableCell>
                    <TableCell className="dark:text-gray-300">
                      {order.tat ? `${order.tat} mins` : "-"}
                    </TableCell>
                    <TableCell className="dark:text-gray-300">
                      {order.gmv}
                    </TableCell>
                    <TableCell className="dark:text-gray-300">
                      {order.net_amount}
                    </TableCell>
                    <TableCell>
                      <img
                        src={modeIcons[order.mode].icon}
                        alt={order.mode}
                        className={`${modeIcons[order.mode].bgColor} ${
                          modeIcons[order.mode].color
                        } p-1`}
                      />
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={7}
                  className="text-center dark:text-gray-400"
                >
                  No orders found for the selected filters
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <OrderDrawer orderId={selectedOrderId ? Number(selectedOrderId) : null} />

      <CommandDialog open={isCommandOpen} onOpenChange={setIsCommandOpen}>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Actions">
            {COMMANDS.map(command => (
              <CommandItem
                key={command.id}
                value={command.id}
                onSelect={() => {
                  command.action(navigate);
                  mixpanelInstance?.track("command_executed", {
                    command_name: command.name,
                    command_shortcut: command.shortcut.join(" + "),
                  });
                  setIsCommandOpen(false);
                }}
                className="cursor-pointer"
              >
                <div className="flex justify-between w-full">
                  <span>{command.name}</span>
                  <span className="text-sm text-gray-500">
                    {command.shortcut.join(" + ")}
                  </span>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandGroup heading="Orders">
            {orders
              .filter(order =>
                order.invoice_number
                  ?.toLowerCase()
                  .includes(searchFilter.toLowerCase()),
              )
              .slice(0, 5)
              .map(order => (
                <CommandItem
                  key={order.id}
                  value={order.invoice_number}
                  onSelect={() => {
                    updateSearchParams("orderId", order.id);
                    setIsCommandOpen(false);
                  }}
                  className="cursor-pointer"
                >
                  <div className="flex flex-col">
                    <span>{order.invoice_number}</span>
                    <span className="text-sm text-gray-500">
                      {order.partner} - {order.status}
                    </span>
                  </div>
                </CommandItem>
              ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </div>
  );
};
