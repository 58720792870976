import { useState, useEffect } from "react";
import api from "../../lib/api";
import type { PageLayout } from "../../../../src/types";
import PageLayoutForm from "../virtual_pharmacy/PageLayoutForm";
import MobilePreview from "../virtual_pharmacy/MobilePreview";
import { useToast } from "../../hooks/use-toast";
import { Button } from "../ui/button";

function PageLayouts() {
  const [layouts, setLayouts] = useState<PageLayout[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [layoutData, setLayoutData] = useState<PageLayout | null>(null);
  const [isCreated, setIsCreated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const loadLayouts = async () => {
      try {
        setIsLoading(true);
        const response = await api.fetchPageLayouts();
        if (response && "error" in response) {
          toast({
            title: "Error",
            description: response.error,
            variant: "destructive",
          });
        } else {
          setLayouts(response || []);
        }
      } catch (err: any) {
        toast({
          title: "Error",
          description: err.message,
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };
    loadLayouts();
  }, [isCreated]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setLayoutData(null);
  };
  const handleOpenPreview = (layoutData: PageLayout) => {
    setLayoutData(layoutData);
    setIsPreviewOpen(true);
  };
  const handleClosePreview = () => setIsPreviewOpen(false);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500">
          .
        </div>
      </div>
    );
  }

  const handleEditLayout = (layout: PageLayout) => {
    setLayoutData(layout);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDeleteLayout = async (id: string) => {
    if (confirm("Are you sure you want to delete this layout?")) {
      const response = await api.deletePageLayout(Number(id));
      if (response && "error" in response) {
        toast({
          title: "Error",
          description: response.error,
          variant: "destructive",
        });
      } else {
        setLayouts(layouts.filter(layout => layout.id !== id));
      }
    } else {
      return;
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex w-full justify-between items-center">
        <h1 className="text-2xl font-bold">Page Layouts</h1>
        <Button onClick={handleOpenModal}>Add New Layout</Button>
      </div>

      {layouts.length === 0 ? (
        <div className="w-full mt-6 text-center text-gray-500">
          No page layouts found
        </div>
      ) : (
        <div className="w-full mt-6">
          <table className="w-full table-auto bg-white dark:bg-gray-900 dark:text-white rounded-lg shadow-sm">
            <thead className="border-b">
              <tr>
                <th className="px-4 py-2 text-left text-gray-600">Name</th>
                <th className="px-4 py-2 text-left text-gray-600">
                  Last Updated at
                </th>
                <th className="px-4 py-2 text-left text-gray-600">Actions</th>
              </tr>
            </thead>
            <tbody>
              {layouts.map(layout => (
                <tr key={layout.id} className="border-b">
                  <td className="px-4 py-2">{layout.page}</td>
                  <td className="px-4 py-2">
                    {new Date(layout.updated_at).toLocaleString()}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      type="button"
                      onClick={() => handleOpenPreview(layout)}
                      className="mr-2 bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium"
                    >
                      Preview
                    </button>
                    <button
                      type="button"
                      onClick={() => handleEditLayout(layout)}
                      className="mr-2 bg-green-500 text-white px-4 py-2 rounded-md text-sm font-medium"
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDeleteLayout(layout.id)}
                      className="bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4">
            <div className="bg-white dark:bg-gray-900 rounded-lg w-full max-w-4xl mx-auto flex flex-col max-h-[90vh]">
              <div className="sticky top-0 bg-white dark:bg-gray-900 px-6 py-4 border-b rounded-t-lg z-10">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-bold dark:text-white">
                    {isEditing ? "Edit Layout" : "Add New Layout"}
                  </h2>
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className="text-gray-500 hover:text-gray-700 dark:text-white"
                  >
                    ✕
                  </button>
                </div>
              </div>
              <div className="overflow-y-auto p-6">
                <PageLayoutForm
                  layoutData={layoutData || undefined}
                  onSuccess={() => {
                    setIsCreated(true);
                    handleCloseModal();
                    setIsEditing(false);
                    setLayoutData(null);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isPreviewOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white dark:bg-gray-900 rounded-lg p-4 w-full max-w-md">
            <div className="flex justify-between mt-4 items-center">
              <h2 className="text-xl font-bold dark:text-white">Preview</h2>
              <button
                type="button"
                onClick={handleClosePreview}
                className="justify-end bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium"
              >
                ✕
              </button>
            </div>
            <MobilePreview layoutData={layoutData} />
          </div>
        </div>
      )}
    </div>
  );
}

export default PageLayouts;
