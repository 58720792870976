import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "../../hooks/use-toast";
import api from "../../lib/api";
import { orderStatus, orderStatusEnum } from "../../lib/constants";
import { RadioGroup } from "../ui/radio-group";

interface OrderStatusDropdownProps {
	currentStatus: string;
	orderId: number;
}

const OrderStatusDropdown: React.FC<OrderStatusDropdownProps> = ({
	currentStatus,
	orderId,
}) => {
	const [selectedStatus, setSelectedStatus] = useState(currentStatus);
	const { toast } = useToast();

	useEffect(() => {
		setSelectedStatus(currentStatus);
	}, [currentStatus]);

	const availableStatuses = useMemo(() => {
		const currentIndex = orderStatus.indexOf(currentStatus);
		const options = [];

		if (currentIndex > 0) {
			options.push(orderStatus[currentIndex - 1]);
		}

		options.push(currentStatus);
		if (
			currentStatus === orderStatusEnum.Pending ||
			currentStatus === orderStatusEnum.Accepted
		) {
			options.push(orderStatusEnum.Cancelled);
		}

		if (currentIndex < orderStatus.length - 1) {
			options.push(orderStatus[currentIndex + 1]);
		}

		return options;
	}, [currentStatus]);

	const handleSubmit = async () => {
		if (selectedStatus !== currentStatus) {
			try {
				api.updateOrderStatus(orderId, selectedStatus).then((response) => {
					window.location.reload();
				});
				toast({
					title: "Order Status Updated",
					description: `${selectedStatus}`,
				});
			} catch (error) {
				toast({
					title: "Failed to update status",
					description: `${error}`,
					variant: "destructive",
				});
			}
		}
	};

	return (
		<div className="w-full max-w-2xl">
			<div className="bg-white dark:bg-gray-800 rounded-lg p-2 mt-2 shadow-sm border border-gray-100 dark:border-gray-700">
				<div className="text-lg font-medium dark:text-white mb-2">Status</div>
				<div className="flex items-center gap-2">
					<div className="relative flex-1">
						<RadioGroup
							value={selectedStatus}
							onValueChange={setSelectedStatus}
						>
							<div className="flex flex-col gap-2">
								{availableStatuses.map((status) => (
									<label
										key={status}
										className="flex items-center gap-2 text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors cursor-pointer"
									>
										<input
											type="radio"
											name="status"
											value={status}
											checked={selectedStatus === status}
											onChange={() => setSelectedStatus(status)}
											className="text-primary-600 dark:text-primary-400 focus:ring-primary-500 dark:focus:ring-primary-400 h-4 w-4"
										/>
										<span>{status}</span>
									</label>
								))}
							</div>
						</RadioGroup>
					</div>

					<button
						type="button"
						onClick={handleSubmit}
						disabled={selectedStatus === currentStatus}
						className={`px-6 py-2 rounded-lg text-white font-medium transition-colors
              ${
								selectedStatus !== currentStatus
									? "bg-gray-700 hover:bg-gray-800 dark:bg-gray-600 dark:hover:bg-gray-700"
									: "bg-gray-400 dark:bg-gray-600 cursor-not-allowed"
							}`}
					>
						Update
					</button>
				</div>
			</div>
		</div>
	);
};

export default OrderStatusDropdown;
