import Tracker from "@openreplay/tracker";
import { OPENREPLAY_CONFIG } from "./constants";

class TrackerService {
	private tracker: Tracker;
	private static instance: TrackerService;

	private constructor() {
		this.tracker = new Tracker({
			projectKey: OPENREPLAY_CONFIG.projectKey,
			ingestPoint: OPENREPLAY_CONFIG.ingestPoint,
		});
	}

	public static getInstance(): TrackerService {
		if (!TrackerService.instance) {
			TrackerService.instance = new TrackerService();
		}
		return TrackerService.instance;
	}

	public start(): void {
		this.tracker.start();
	}

	public stop(): void {
		this.tracker.stop();
	}

	public identify(userId: string, metadata?: Record<string, any>): void {
		this.tracker.setUserID(userId);
		if (metadata) {
			Object.entries(metadata).forEach(([key, value]) => {
				this.tracker.setMetadata(key, String(value));
			});
		}
	}

	public trackEvent(name: string, payload?: Record<string, any>): void {
		this.tracker.event(name, payload);
	}
}

export const tracker = TrackerService.getInstance();
