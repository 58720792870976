import type { PageLayout, Section } from "../../../../src/types";

const MobilePreview: React.FC<{ layoutData: PageLayout | null }> = ({
  layoutData,
}) => {
  const getGridCols = (length: number): string => {
    switch (length) {
      case 1:
        return "grid-cols-1";
      case 2:
        return "grid-cols-2";
      case 3:
        return "grid-cols-3";
      default:
        return "grid-cols-4";
    }
  };

  const renderSection = (section: Section) => {
    switch (section.type) {
      case "banner":
        return (
          <div className="w-full relative">
            {section.data.image ? (
              <img
                src={section.data.image}
                alt="Banner"
                className="w-full h-full object-contain"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <span className="text-gray-400">Banner Image</span>
              </div>
            )}
          </div>
        );

      case "carousel":
        return (
          <div className="w-full overflow-hidden">
            <div className="flex space-x-4 overflow-x-auto py-4 scrollbar-hide">
              {section.data.map((item, index) => (
                <div key={item.image} className="flex-none w-full h-full">
                  {item.image ? (
                    <img
                      src={item.image}
                      alt={`Carousel ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200 rounded-lg flex items-center justify-center">
                      <span className="text-gray-400">
                        Carousel Image {index + 1}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        );

      case "card":
        return (
          <div className="w-full overflow-hidden">
            <div className={`grid ${getGridCols(section.data.length)} gap-2`}>
              {section.data.map((item, index) => (
                <div
                  key={item.title || index}
                  className="bg-white p-2 rounded-lg shadow"
                >
                  <div className="flex flex-col flex-start">
                    {item.icon ? (
                      <img
                        src={item.icon}
                        alt={`Icon ${index + 1}`}
                        className="w-12 h-12 object-contain mb-2"
                      />
                    ) : (
                      <div className="w-12 h-12 bg-gray-200 rounded-full mb-2 flex items-center justify-center">
                        <span className="text-gray-400">Icon</span>
                      </div>
                    )}
                    {item.subtitle && (
                      <p className="text-sm text-gray-500">{item.subtitle}</p>
                    )}
                    <h3 className="font-medium text-gray-900">
                      {item.title || "Title"}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );

      case "categories":
        return (
          <div className="w-full overflow-hidden">
            <h4 className="text-lg font-medium text-gray-900">
              {section.data.title}
            </h4>
            <div className={`grid ${getGridCols(section.data.columns)} gap-2`}>
              {section.data.items.map(item => (
                <div key={item.title} className="flex flex-col items-center">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <p className="text-sm text-gray-500 text-center">
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-md mx-auto p-6">
      {/* Mobile Device Frame */}
      <div className="relative mx-auto w-[320px] h-[640px] bg-white rounded-[3rem] shadow-xl border-8 border-gray-800">
        {/* Screen Content */}
        <div className="h-full overflow-y-auto py-8 px-4">
          {/* Page Title */}
          <h1 className="text-xl font-bold text-center">
            {layoutData?.page || "Untitled Page"}
          </h1>

          {/* Layout Sections */}
          <div className="space-y-6">
            {layoutData?.metadata?.map((section: Section, index: number) => (
              <div key={index}>{renderSection(section)}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePreview;
