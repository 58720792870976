import axios from "axios";
import { mockDataService } from "../services/mockDataService";
import type { Entity, OrderFilters } from "./types";
import type { AddPageLayoutData } from "../../../src/types";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const api = {
	getOrders: async (filters: OrderFilters) => {
		try {
			const response = await axios.get(`${API_BASE_URL}/get_orders`, {
				params: filters,
			});

			return response.data;
		} catch (error) {
			console.error("Error fetching orders:", error);
			throw error;
		}
	},
	getGroupedOrders: (filters: any) => {
		return mockDataService.getGroupedOrders(filters);
	},
	getOrder: async (invoice_number: string) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/get_order_detail/${invoice_number}`,
			);
			return response.data;
		} catch (error) {
			console.error("Error fetching order:", error);
			throw error;
		}
	},
	updateOrderStatus: async (order_id: number, status: string) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/update_order_status_by_id`,
				{
					order_id,
					status,
				},
			);
			return response.data;
		} catch (error) {
			console.error("Error updating order status:", error);
			throw error;
		}
	},
	getEntities: async (order_id: number) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/get_entities/${order_id}`,
			);
			return response.data;
		} catch (error) {
			console.error("Error fetching entities:", error);
			throw error;
		}
	},
	transferOrder: async (invoice_number: string, entity_id: number) => {
		try {
			const response = await axios.post(`${API_BASE_URL}/transfer_order`, {
				invoice_number,
				transfer_to: entity_id,
			});
			return response.data;
		} catch (error) {
			console.error("Error transferring order:", error);
			throw error;
		}
	},
	subscribeToKnock: async (email: string, userId: string) => {
		try {
			const response = await axios.post(`${API_BASE_URL}/knock/subscribe`, {
				email,
				userId,
			});
			return response.data;
		} catch (error) {
			console.error("Error subscribing to Knock:", error);
			throw error;
		}
	},
	getPharmacyStats: (filters: any) => {
		return mockDataService.getPharmacyStats(filters);
	},
	getStatusStats: (filters: any) => {
		return mockDataService.getStatusStats(filters);
	},
	searchPincodes: async (search_query: string, access_level: string) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/search_pincodes/${search_query}`,
				{ params: access_level ? { access_level } : undefined },
			);
			return response.data;
		} catch (error) {
			console.error("Error searching pincodes:", error);
			throw error;
		}
	},
	getAllEntities: async (entities_ids: string) => {
		try {
			const response = await axios.get(`${API_BASE_URL}/get_all_entities/`, {
				params: { entities_ids },
			});
			return response.data;
		} catch (error) {
			console.error("Error fetching all entities:", error);
			throw error;
		}
	},
	getEntityPincodes: async (entity_id: number) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/entities/pincodes/${entity_id}`,
			);
			return response.data;
		} catch (error) {
			console.error("Error fetching entity pincodes:", error);
			throw error;
		}
	},
	updateEntityPincodes: async (entity_id: number, pincode_ids: number[]) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/entities/update_pincode_list`,
				{ entity_id, pincode_ids },
			);
			return response.data;
		} catch (error) {
			console.error("Error updating entity pincodes:", error);
			throw error;
		}
	},
	createEntity: async (entity: Entity) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/entities/create`,
				entity,
			);
			return response.data;
		} catch (error) {
			console.error("Error creating entity:", error);
			throw error;
		}
	},
	updateEntity: async (entity_id: number, entity: any) => {
		try {
			const response = await axios.post(`${API_BASE_URL}/entities/update`, {
				entity_id,
				...entity,
			});
			return response.data;
		} catch (error) {
			console.error("Error updating entity:", error);
			throw error;
		}
	},
	getAccessLevel: async (user_id: string) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/get_access_level/${user_id}`,
			);
			return response.data;
		} catch (error) {
			console.error("Error fetching access level:", error);
			throw error;
		}
	},
	getUserList: async (user_id: string) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/get_user_list/${user_id}`,
			);
			return response.data;
		} catch (error) {
			console.error("Error fetching user list:", error);
			throw error;
		}
	},
	getAccessLevels: async (user_id: string) => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/get_access_levels/${user_id}`,
			);
			return response.data;
		} catch (error) {
			console.error("Error fetching access levels:", error);
			throw error;
		}
	},
	createAccess: async (data: any) => {
		try {
			const response = await axios.post(`${API_BASE_URL}/create_access/`, data);
			return response.data;
		} catch (error) {
			console.error("Error creating access:", error);
			throw error;
		}
	},
	updateAccess: async (data: any) => {
		try {
			const response = await axios.post(`${API_BASE_URL}/update_access`, data);
			return response.data;
		} catch (error) {
			console.error("Error updating access:", error);
			throw error;
		}
	},
	deleteAccess: async (id: number) => {
		try {
			const response = await axios.delete(
				`${API_BASE_URL}/delete_access/${id}`,
			);
			return response.data;
		} catch (error) {
			console.error("Error deleting access:", error);
			throw error;
		}
	},
	fetchPageLayouts: async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/get_page_layouts`);
			return response.data;
		} catch (error) {
			console.error("Error fetching page layouts:", error);
			throw error;
		}
	},
	createPageLayout: async (layout: AddPageLayoutData) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/create_page_layout`,
				layout,
			);
			return response.data;
		} catch (error) {
			console.error("Error creating page layout:", error);
			throw error;
		}
	},
	updatePageLayout: async (layout_id: number, layout: AddPageLayoutData) => {
		try {
			const response = await axios.post(`${API_BASE_URL}/update_page_layout`, {
				id: layout_id,
				...layout,
			});
			return response.data;
		} catch (error) {
			console.error("Error updating page layout:", error);
			throw error;
		}
	},
	deletePageLayout: async (layout_id: number) => {
		try {
			const response = await axios.delete(
				`${API_BASE_URL}/delete_page_layout/${layout_id}`,
			);
			return response.data;
		} catch (error) {
			console.error("Error deleting page layout:", error);
			throw error;
		}
	},
};

export default api;
