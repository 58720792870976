import { useEffect, useState } from "react";
import { useToast } from "../../hooks/use-toast";
import { api } from "../../lib/api";
import { useAuth } from "../../lib/authContext";
import { entityTypeColors } from "../../lib/constants";
import type { Entity } from "../../lib/types";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Switch } from "../ui/switch";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../ui/table";
import { EntityDialog } from "./EntityDialog";
import { PincodeDialog } from "./PincodeDialog";
export const EntityTable = () => {
	const [entities, setEntities] = useState<Entity[]>([]);
	const [searchFilter, setSearchFilter] = useState("");
	const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [isPincodeOpen, setIsPincodeOpen] = useState(false);
	const { accessLevel } = useAuth();
	const { toast } = useToast();

	const fetchEntities = async () => {
		try {
			const result = await api.getAllEntities(
				accessLevel?.entities?.join(",") || "",
			);
			setEntities(result.results);
		} catch (error) {
			console.error("Failed to fetch entities:", error);
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: fetchEntities is not a dependency
	useEffect(() => {
		fetchEntities();
	}, []);

	const handleStatusToggle = async (
		entityId: number,
		currentStatus: boolean,
	) => {
		try {
			await api.updateEntity(entityId, { is_active: !currentStatus });
			toast({
				title: "Entity Status Updated",
				description: `${
					entities.find((entity) => entity.id === entityId)?.name
				} status changed to ${!currentStatus ? "active" : "inactive"}`,
			});
			fetchEntities();
		} catch (error) {
			console.error("Failed to update entity status:", error);
			toast({
				title: "Failed to update entity status",
				description: `${error}`,
				variant: "destructive",
			});
		}
	};

	const filteredEntities = entities.filter((entity) =>
		entity.name.toLowerCase().includes(searchFilter.toLowerCase()),
	);

	return (
		<div className="space-y-4 min-h-screen bg-white dark:bg-gray-900 p-6">
			<div className="flex justify-between items-center">
				<h1 className="text-2xl font-bold dark:text-white">Entities</h1>
				<Button
					onClick={() => {
						setSelectedEntity(null);
						setIsEditOpen(true);
					}}
				>
					Add Entity
				</Button>
			</div>

			<div className="flex justify-between items-center">
				<div className="flex space-x-2">
					<Input
						type="text"
						placeholder="Search Entity"
						className="w-64 dark:bg-gray-800 dark:text-white dark:border-gray-700"
						value={searchFilter}
						onChange={(e) => setSearchFilter(e.target.value)}
					/>
				</div>
			</div>

			<div className="rounded-md border dark:border-gray-700">
				<Table>
					<TableHeader>
						<TableRow className="dark:border-gray-700 dark:bg-gray-800">
							<TableHead className="dark:text-gray-400">Name</TableHead>
							<TableHead className="dark:text-gray-400">Type</TableHead>
							<TableHead className="dark:text-gray-400">Branch ID</TableHead>
							<TableHead className="dark:text-gray-400">Client ID</TableHead>
							<TableHead className="dark:text-gray-400">Status</TableHead>
							<TableHead className="dark:text-gray-400">Actions</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{filteredEntities.map((entity) => (
							<TableRow
								key={entity.id}
								className="dark:hover:bg-gray-700 dark:border-gray-700 dark:bg-gray-800"
							>
								<TableCell className="dark:text-gray-300">
									{entity.name}
								</TableCell>
								<TableCell className="dark:text-gray-300">
									<Badge className={`${entityTypeColors[entity.type]}`}>
										{entity.type}
									</Badge>
								</TableCell>
								<TableCell className="dark:text-gray-300">
									{entity.branch_id}
								</TableCell>
								<TableCell className="dark:text-gray-300">
									{entity.client_id}
								</TableCell>
								<TableCell>
									<Switch
										checked={entity.is_active}
										onCheckedChange={() =>
											handleStatusToggle(entity.id, entity.is_active)
										}
									/>
								</TableCell>
								<TableCell>
									<div className="flex space-x-2">
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												setSelectedEntity(entity);
												setIsEditOpen(true);
											}}
										>
											Edit
										</Button>
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												setSelectedEntity(entity);
												setIsPincodeOpen(true);
											}}
										>
											Pincodes
										</Button>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>

			<EntityDialog
				open={isEditOpen}
				onOpenChange={setIsEditOpen}
				entity={selectedEntity}
				onSave={fetchEntities}
			/>

			<PincodeDialog
				open={isPincodeOpen}
				onOpenChange={setIsPincodeOpen}
				entity={selectedEntity}
			/>
		</div>
	);
};
