import type React from "react";
import { useEffect, useState } from "react";
import { useToast } from "../../hooks/use-toast";
import api from "../../lib/api";
import { Command, CommandInput, CommandItem, CommandList } from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

interface EntityDropDownProps {
	id: number;
	name: string;
}

const EntityDropDown: React.FC<{ orderId: string; invoiceNumber: string }> = ({
	orderId,
	invoiceNumber,
}) => {
	const [selectedEntity, setSelectedEntity] = useState<number | null>(null);
	const [entities, setEntities] = useState<EntityDropDownProps[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const { toast } = useToast();

	useEffect(() => {
		api.getEntities(Number(orderId)).then((response) => {
			setEntities(response.data);
		});
	}, [orderId]);

	const handleSubmit = async () => {
		try {
			api.transferOrder(invoiceNumber, selectedEntity ?? 0).then((response) => {
				toast({
					title: "Order Transferred",
					description: `${invoiceNumber} transferred to ${
						entities.find((entity) => entity.id === selectedEntity)?.name
					}`,
				});
			});
		} catch (error) {
			toast({
				title: "Failed to transfer order",
				description: `${error}`,
				variant: "destructive",
			});
		}
	};

	return (
		<div className="w-full max-w-2xl mt-2">
			<div className="bg-white dark:bg-gray-800 rounded-lg p-2 shadow-sm border border-gray-100 dark:border-gray-700">
				<div className="text-sm font-medium dark:text-white mb-2">
					Fullfilment Partner
				</div>
				<div className="flex items-center gap-2">
					<div className="relative flex-1">
						<Popover>
							<PopoverTrigger className="w-full">
								<button
									type="button"
									className="w-full bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600
                    rounded-lg pl-8 py-2 pr-10 focus:outline-none text-gray-700 dark:text-gray-200
                    hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors"
								>
									{selectedEntity
										? entities.find((entity) => entity.id === selectedEntity)
												?.name
										: "Select a new partner"}
								</button>
							</PopoverTrigger>
							<PopoverContent className="dark:bg-gray-800 dark:border-gray-700">
								<div className="dark:bg-gray-800">
									<input
										type="text"
										placeholder="Search for a partner..."
										className="dark:bg-gray-700 dark:text-gray-200 dark:placeholder-gray-400 w-full p-2 border-1 border-gray-600 rounded-lg"
										onChange={(e) => setSearchTerm(e.target.value)}
									/>
									<div className="dark:bg-gray-800">
										{entities
											.filter((entity) =>
												entity.name
													.toLowerCase()
													.includes(searchTerm.toLowerCase()),
											)
											.map((entity) => (
												<div
													key={entity.id}
													onClick={() => setSelectedEntity(entity.id)}
													onKeyUp={() => setSelectedEntity(entity.id)}
													onKeyDown={() => setSelectedEntity(entity.id)}
													className={`dark:text-gray-200 cursor-pointer ${
														selectedEntity === entity.id
															? "dark:bg-gray-700"
															: "dark:bg-gray-800"
													} p-2`}
												>
													{entity.name}
												</div>
											))}
									</div>
								</div>
							</PopoverContent>
						</Popover>
					</div>

					<button
						type="button"
						onClick={handleSubmit}
						disabled={!selectedEntity}
						className={`px-6 py-2 rounded-lg text-white font-medium transition-colors
              ${
								selectedEntity
									? "bg-gray-700 hover:bg-gray-800 dark:bg-gray-600 dark:hover:bg-gray-700"
									: "bg-gray-400 dark:bg-gray-600 cursor-not-allowed"
							}`}
					>
						Transfer
					</button>
				</div>
			</div>
		</div>
	);
};

export default EntityDropDown;
