import {
  LayoutTemplate,
  ListOrdered,
  Moon,
  Store,
  Sun,
  User,
  Users,
  Warehouse,
} from "lucide-react";
import type React from "react";
import { Link } from "react-router-dom";
import {
  Sidebar as ShadcnSidebar,
  SidebarContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
} from "../../components/ui/sidebar";
import { initAnalytics } from "../../lib/analytics";
import { useTheme } from "../../lib/themeContext";
import pillPlusLogo from "../../pillplus-logo.png";
import { LogoutButton } from "../auth/logoutButton";

export const Sidebar: React.FC = () => {
  const { theme, setTheme } = useTheme();
  const mixpanel = initAnalytics();

  const handleThemeChange = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    mixpanel.track("theme_changed", {
      new_theme: newTheme,
      previous_theme: theme,
    });
  };

  return (
    <SidebarProvider defaultOpen={false}>
      <ShadcnSidebar
        collapsible="icon"
        side="left"
        className="flex flex-col justify-between dark:bg-gray-900 dark:text-white dark:border-gray-700"
      >
        <div>
          <SidebarHeader className="dark:border-gray-700">
            <Link to="/">
              <img
                src={pillPlusLogo}
                alt="PillPlus Logo"
                className="h-8 w-auto mx-auto"
              />
            </Link>
          </SidebarHeader>
          <SidebarContent>
            <SidebarMenu>
              <SidebarMenuItem
                title="Manage Orders"
                className="dark:text-gray-300"
              >
                <Link to="/orders">
                  <SidebarMenuButton
                    icon={<ListOrdered size={20} />}
                    className="dark:hover:bg-gray-800"
                  >
                    Orders
                  </SidebarMenuButton>
                </Link>
              </SidebarMenuItem>
              <SidebarMenuItem
                title="View Pharmacies"
                className="dark:text-gray-300"
              >
                <Link to="/pharmacies">
                  <SidebarMenuButton
                    icon={<Store size={20} />}
                    className="dark:hover:bg-gray-800"
                  >
                    Pharmacies
                  </SidebarMenuButton>
                </Link>
              </SidebarMenuItem>
              <SidebarMenuItem
                title="Manage Entities"
                className="dark:text-gray-300"
              >
                <Link to="/entities">
                  <SidebarMenuButton
                    icon={<Warehouse size={20} />}
                    className="dark:hover:bg-gray-800"
                  >
                    Entities
                  </SidebarMenuButton>
                </Link>
              </SidebarMenuItem>
              <SidebarMenuItem
                title="Manage Users"
                className="dark:text-gray-300"
              >
                <Link to="/users">
                  <SidebarMenuButton
                    icon={<Users size={20} />}
                    className="dark:hover:bg-gray-800"
                  >
                    Users
                  </SidebarMenuButton>
                </Link>
              </SidebarMenuItem>
              <SidebarMenuItem
                title="Page Layouts"
                className="dark:text-gray-300"
              >
                <Link to="/page_layouts">
                  <SidebarMenuButton
                    icon={<LayoutTemplate size={20} />}
                    className="dark:hover:bg-gray-800"
                  >
                    Page Layouts
                  </SidebarMenuButton>
                </Link>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarContent>
        </div>
        <div className="mb-14">
          <SidebarMenuItem title="Toggle theme" className="dark:text-gray-300">
            <SidebarMenuButton
              icon={
                theme === "dark" ? (
                  <Moon size={20} className="text-gray-300" />
                ) : (
                  <Sun size={20} />
                )
              }
              className="dark:hover:bg-gray-800"
              onClick={handleThemeChange}
            >
              {theme === "dark" ? "Dark Mode" : "Light Mode"}
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem
            title="Sign Out of Account"
            className="dark:text-gray-300"
          >
            <SidebarMenuButton
              icon={<LogoutButton size={20} />}
              className="dark:hover:bg-gray-800"
            >
              Sign Out
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem
            title="View Profile Settings"
            className="dark:text-gray-300"
          >
            <SidebarMenuButton
              icon={<User size={20} />}
              className="dark:hover:bg-gray-800"
            >
              Profile
            </SidebarMenuButton>
          </SidebarMenuItem>
        </div>
      </ShadcnSidebar>
      <SidebarTrigger className="dark:bg-gray-900 dark:text-white dark:border-gray-700 dark:hover:bg-gray-800" />
    </SidebarProvider>
  );
};
