import * as Dialog from "@radix-ui/react-dialog";
import { X } from "lucide-react";
import type * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../lib/api";
import type { Order } from "../../lib/types";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import EntityDropDown from "./EntityDropDown";
import { OrderHistory } from "./OrderHistory";
import OrderStatusDropdown from "./OrderStatusDropdown";

interface OrderDrawerProps {
	orderId: number | null;
}

export const OrderDrawer: React.FC<OrderDrawerProps> = ({ orderId }) => {
	const [order, setOrder] = useState<Order | null>(null);
	const navigate = useNavigate();

	const onClose = () => {
		const params = new URLSearchParams(window.location.search);
		params.delete("orderId");
		navigate(`/orders?${params.toString()}`);
	};

	useEffect(() => {
		if (orderId) {
			api.getOrder(orderId.toString()).then((response) => {
				setOrder(response.data);
			});
		}
	}, [orderId]);

	return (
		<Dialog.Root open={!!orderId} onOpenChange={onClose}>
			<Dialog.Portal>
				<Dialog.Overlay className="fixed inset-0 bg-black/50" />
				<Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-4/5 w-4/5 bg-white dark:bg-gray-900 shadow-lg overflow-auto">
					<Dialog.Title className="text-2xl font-bold text-gray-900 dark:text-white pl-4 pt-4">
						Order Details #{order?.invoice_number}
					</Dialog.Title>
					<div className="p-4 flex mt-3">
						<div className="w-2/5 mr-4">
							<Dialog.Close asChild>
								<button
									type="button"
									className="absolute top-4 right-4 inline-flex items-center justify-center rounded-full h-10 w-10 transition-colors hover:bg-gray-100 dark:hover:bg-gray-800 dark:text-gray-300"
									aria-label="Close"
								>
									<X />
								</button>
							</Dialog.Close>
							{order?.id && (
								<>
									<OrderMetadata order={order} />
									<OrderActions order={order} />
								</>
							)}
						</div>
						<div className="border-l border-gray-200 dark:border-gray-700 w-1 mx-4" />
						<div className="w-3/5">
							{order && (
								<Tabs defaultValue="tracking" className="mt-6">
									<TabsList className="grid w-full grid-cols-3 dark:bg-gray-800">
										<TabsTrigger
											value="tracking"
											className="dark:data-[state=active]:bg-gray-700 dark:text-gray-300"
										>
											Tracking
										</TabsTrigger>
										<TabsTrigger
											value="actions"
											className="dark:data-[state=active]:bg-gray-700 dark:text-gray-300"
										>
											Items
										</TabsTrigger>
										<TabsTrigger
											value="history"
											className="dark:data-[state=active]:bg-gray-700 dark:text-gray-300"
										>
											History
										</TabsTrigger>
									</TabsList>
									<TabsContent value="tracking" className="dark:text-gray-300">
										<OrderTracking orderId={order.id} />
									</TabsContent>
									<TabsContent value="actions" className="dark:text-gray-300">
										<OrderItems order={order} />
									</TabsContent>
									<TabsContent value="history" className="dark:text-gray-300">
										<div className="bg-white dark:bg-gray-800 rounded-lg shadow">
											<OrderHistory order={order} />
										</div>
									</TabsContent>
								</Tabs>
							)}
						</div>
					</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

const OrderItems: React.FC<{ order: Order }> = ({ order }) => {
	return (
		<div className="overflow-x-auto">
			<table className="min-w-full divide-y divide-gray-200">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500 uppercase"
						>
							Item
						</th>
						<th
							scope="col"
							className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500 uppercase"
						>
							Quantity
						</th>
						<th
							scope="col"
							className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500 uppercase"
						>
							Price
						</th>
						<th
							scope="col"
							className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500 uppercase"
						>
							Total
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{order.items.map((item) => (
						<tr key={item.product_id} className="hover:bg-gray-50">
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="text-sm font-medium text-gray-900">
									{item.product_id}
								</div>
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="text-sm text-right text-gray-500">
									{item.sale_quantity}
								</div>
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="text-sm text-right text-gray-500">
									{item.selling_price.toFixed(1)}
								</div>
							</td>
							<td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
								{(item.selling_price * item.sale_quantity).toFixed(1)}
							</td>
						</tr>
					))}
					<tr>
						<td colSpan={3} className="px-6 py-4 whitespace-nowrap">
							<div className="text-sm font-medium text-gray-900">Total</div>
						</td>
						<td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
							{order.items
								.reduce(
									(acc, item) => acc + item.selling_price * item.sale_quantity,
									0,
								)
								.toFixed(1)}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

const OrderMetadata: React.FC<{ order: Order }> = ({ order }) => {
	return (
		<div className="space-y-2">
			<div className="grid grid-cols-2 gap-2 text-sm dark:text-gray-300">
				<span className="font-medium text-gray-400">Vendor</span>
				<span className="text-right">{order.provider_name}</span>
				<span className="font-medium text-gray-400">Order ID</span>
				<span className="text-right">{order.id}</span>
				<span className="font-medium text-gray-400">Order placed at</span>
				<span className="text-right">
					{new Date(order.created_at).toLocaleString()}
				</span>
				<span className="font-medium text-gray-400">Last Updated</span>
				<span className="text-right">
					{new Date(order.updated_at).toLocaleString()}
				</span>
				<span className="font-medium text-gray-400">TAT</span>
				<span className="text-right">{order.tat}</span>
				<span className="font-medium text-gray-400">Delivery Mode</span>
				<span className="text-right">{order.mode}</span>
			</div>
		</div>
	);
};

const OrderTracking: React.FC<{ orderId: string }> = ({ orderId }) => {
	return (
		<div className="dark:text-gray-300">
			Tracking component for order {orderId}
		</div>
	);
};

const OrderActions: React.FC<{ order: Order }> = ({ order }) => {
	if (order.transferred_order && order.transferred_order !== Number(order.id)) {
		return (
			<div className="text-center mt-2 dark:text-gray-300">
				Order has already been transferred to{" "}
				<a
					href={`/orders?orderId=${order.transferred_order}`}
					className="text-sm text-blue-500 dark:text-blue-400 mt-2 link hover:underline"
				>
					{order.transferred_order}
				</a>
			</div>
		);
	}

	return (
		<div>
			<div className="space-y-2">
				<OrderStatusDropdown
					currentStatus={order.status}
					orderId={Number(order.id)}
				/>
			</div>

			<div className="space-y-2">
				<EntityDropDown
					orderId={order.id}
					invoiceNumber={order.invoice_number ?? ""}
				/>
			</div>
		</div>
	);
};
